import React from "react";

import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import { Col, Row } from "react-bootstrap";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import { PageProps } from "gatsby";
import DocumentationSection from "components/docs/DocumentationSection";
import CodeExample from "components/docs/code-examples/CodeExample";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";
import styled from "styled-components";
import { CenteredDoc } from "./target-fields";
import CustomMappings from "../../../assets/images/docs/guides/field-mapping/CustomMappings.svg";
import IntegWideTargetFieldConfig from "../../../assets/images/docs/guides/field-mapping/IntegWideTargetFieldConfig.svg";
const FittedCodeExample = styled(CodeExample)`
  width: 640px;
  margin: auto;
  padding-top: 22px;
`;

/**
 * Creates a Target Fields page for Field Mapping guide
 */
const AccessMappedData = ({
  location,
}: PageProps<unknown, unknown, Record<string, unknown> | null>) => {
  const title = "Access Mapped Data";
  const description =
    "Access Field Mappings in your Merge Dashboard and through Merge's Unified API.";

  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} />
      <DocumentationSection title="Dashboard View">
        <>
          <p>
            You can view all mappings made by both you and your users in your dashboard in the Field
            Mapping section of each Linked Account&apos;s page.
          </p>
          <p>
            These mappings can include <strong>Linked Account-specific mappings</strong> to{" "}
            <strong>Linked Account-specific mappings</strong>,{" "}
            <strong>Linked Account-specific mappings</strong> to{" "}
            <strong>organization-wide target fields</strong>, and{" "}
            <strong>organization-wide Integration mappings</strong> for{" "}
            <strong>organization-wide target fields</strong>.
          </p>
          <CenteredDoc className="my-9">
            <CustomMappings className="rounded-lg" />
          </CenteredDoc>
          <p className="mb-0">
            Each target field&apos;s page also shows any configured{" "}
            <strong>integration-wide mappings</strong>.
          </p>
          <CenteredDoc className="mt-9">
            <IntegWideTargetFieldConfig className="rounded-lg" />
          </CenteredDoc>
        </>
      </DocumentationSection>
      <hr className="my-9" />
      <DocumentationSection title="API Response" className="mb-9">
        <>
          <p>
            Field Mapping data will be automatically accessible in the Common Model responses
            returned from Merge&apos;s Unified API. No parameters are needed.
          </p>
          <p>
            Any Field Mappings created after the linking flow will trigger a full resync for the
            modified Common Model on the next scheduled sync.
          </p>
          <p>
            Below is a sample request to the Application Common Model after mapping is complete,
            showing both organization-defined and Linked Account-level targets.
          </p>

          <CenteredDoc>
            <FittedCodeExample
              folder="field-mapping/access-mapped-data"
              codeBlockName="Sample response - Application with Field Mappings"
              fileBaseName="application-with-field-mappings"
              colorScheme="light"
              hasLineNumbers={false}
              isVerticallyPadded={false}
              hideCopyButtonWithHeader
            />
          </CenteredDoc>
        </>
      </DocumentationSection>
      <CrossGuideLinks location={location} usesQuaternaryLinks />
    </StaticPageContainer>
  );
};

export default AccessMappedData;
